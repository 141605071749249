@import 'grid.scss';
.calch {
	display: table;
	width: 100%;
}
.hidden {
	display: none;
}
.hidden2 {
	display: none !important;
}
.abso {
	position: absolute;
}
.clear {
	clear: both;
}
.opacity0 {
	opacity: 0;
}
.centered {
	margin: 0 auto;
	display: block;
}
.circle {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}
.ucase {
	text-transform: uppercase;
}
.trans05 {
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
}
.delay10 {
	-webkit-transition-delay: 1s;
	-moz-transition-delay: 1s;
	transition-delay: 1s;
}
.tcenter {
	text-align: center;
}
.tright {
	text-align: right;
}
.tleft {
	text-align: left;
}
.fleft {
	float: left;
}
.fright {
	float: right;
}
.darkgrey-text {
	color: #4a5666;
}
.grey-text {
	color: #d5dde7;
}
.lightgrey-text {
	color: #acbbd0;
}
.light1-bg {
	background: #eff0f5;
}
.light2-bg {
	background: #f7f8fa;
}
.orange-text {
	color: #f29c17;
}
.orange-bg {
	background: #f29c17;
}
.blue-text {
	color: #22bafb;
}
.blue-bg {
	background: #22bafb;
}
.yellow-bg {
	background: #f6c12b;
}
html {
	height: 100%;
	background: #fff;
	color: #75848d;
}
body {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	color: #75848d;
	min-height: 100%;
	letter-spacing: -0.25px;
}
a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
hr {
	background: #eff0f5;
	margin: 30px 0;
	height: 1px;
	border: none;
}
h1 {
	font-family: 'Montserrat', sans-serif;
	font-weight: normal;
	letter-spacing: -1px;
	color: #4a5666;
	margin: 0 0 30px;
	font-size: 36px;
	line-height: 36px;
}
h2 {
	font-family: 'Montserrat', sans-serif;
	font-weight: normal;
	letter-spacing: -1px;
	color: #4a5666;
	margin: 0 0 30px;
	font-size: 27px;
	line-height: 30px;
}
h3 {
	font-family: 'Montserrat', sans-serif;
	font-weight: normal;
	letter-spacing: -1px;
	color: #4a5666;
	margin: 0 0 30px;
	font-size: 22px;
	line-height: 24px;
}
h4 {
	font-family: 'Montserrat', sans-serif;
	font-weight: normal;
	letter-spacing: -1px;
	color: #4a5666;
	margin: 0 0 30px;
	font-size: 20px;
	line-height: 20px;
}
h5 {
	font-family: 'Montserrat', sans-serif;
	font-weight: normal;
	letter-spacing: -1px;
	color: #4a5666;
	margin: 0 0 30px;
	font-size: 18px;
	line-height: 18px;
}
h6 {
	font-family: 'Montserrat', sans-serif;
	font-weight: normal;
	letter-spacing: -1px;
	color: #4a5666;
	margin: 0 0 30px;
	font-size: 16px;
	line-height: 16px;
}
button, .button {
	border: none;
	background: #f29c17;
	color: #fff;
	padding: 10px 15px;
	font-size: 13px;
	border-radius: 3px;
	cursor: pointer;
}
.button.secondary {
	background: #d5dde7;
	color: #4a5666;
}
.logo {
	width: 175px;
	float: left;
	margin: 0 50px 0 0;
}
.relative {
	position: relative;
}
.navigation {
	background: #fafafa;
	height: 100%;
	float: left;
	width: 20%;
	position: fixed;
	padding: 50px 0 30px 0;
	transform: translateX(-400px);
	.logo {
		margin: 0 50px 0 50px;
	}
	>ul {
		clear: both;
		padding: 50px 0 0 0;
	}
	li {
		a {
			font-family: Montserrat, sans-serif;
			font-weight: bold;
			letter-spacing: -1px;
			padding: 5px 0 5px 50px;
			width: 100%;
			color: #4a5666;
			line-height: 30px;
			-webkit-transition: all .5s;
			-moz-transition: all .5s;
			transition: all .5s;
			img {
				float: left;
				margin-right: 10px;
			}
			&:hover {
				border-right: 5px solid #4a5666;
				background: #eaeaea;
			}
		}
		a.active {
			border-right: 5px solid #f29c17;
			color: #f29c17;
			position: relative;
		}
		a.inactive {
			color: #acbbd0;
		}
	}
	ul {
		ul {
			padding: 0 0 10px 40px;
			li {
				a {
					font-weight: normal;
					position: relative;
					border: none !important;
					span {
						z-index: 99;
						position: relative;
						-webkit-transition: all .5s;
						-moz-transition: all .5s;
						transition: all .5s;
					}
					&:after {
						content: '';
						background: #f29c17;
						width: 0;
						height: 100%;
						right: 0;
						position: absolute;
						margin: -5px 0 0;
						z-index: 1;
						-webkit-transition: all .5s;
						-moz-transition: all .5s;
						transition: all .5s;
					}
					&:hover {
						&:after {
							width: 150%;
						}
						span {
							color: #fff;
						}
					}
				}
			}
		}
	}
	span.space {
		display: block;
		height: 20px;
	}
	i {
		position: absolute;
		bottom: 0;
		left: 0;
		font-style: normal;
		font-size: 10px;
		text-transform: uppercase;
		color: #aaa;
		border-left: 5px solid;
		padding: 5px;
	}
	&.opened {
		margin-left: 0;
		transform: translateX(0);
	}
}
article {
	float: left;
	width: 70%;
	padding: 0 30px 45px 0;
	position: relative;
	+ {
		article {
			float: left;
			width: 30%;
			padding: 30px;
			border-radius: 3px;
		}
	}
	h2 {
		background: url("./assets/images/icon_more.png") no-repeat center right;
		+ {
			i.delete {
				position: absolute;
				right: 60px;
				top: 0;
				background: url("./assets/images/icon_delete2.png");
			}
		}
	}
}
section.fullwidth {
	article {
		width: 100%;
		padding: 0 0 45px;
	}
}
.addnew {
	margin: -40px 0 40px !important;
}
header {
	display: block;
	width: 100%;
	float: right;
	.avatar {
		float: right;
		padding: 15px 30px;
		span {
			float: left;
			margin: 5px;
		}
	}
	span {
		height: 50px;
		width: 50px;
		border-radius: 25px;
		float: left;
		overflow: hidden;
		margin: 10px 0 0 -10px;
	}
	figcaption {
		float: left;
		padding: 25px 15px 15px 0;
		font-size: 14px;
		color: #acbbd0;
		font-weight: bold;
	}
	div.avacont {
		+ {
			img {
				padding: 15px;
				cursor: pointer;
			}
		}
		position: relative;
		float: left;
		&:before {
			z-index: 9999;
			content: '';
			width: 5px;
			height: 5px;
			border: 4px solid #fff;
			border-radius: 10px;
			background: red;
			top: 5px;
			right: 5px;
			position: absolute;
		}
	}
	&.opened {
		width: 80%;
		margin-left: 0;
	}
}
.content {
	width: 100%;
	margin-left: 0;
	padding: 50px;
	&.opened {
		width: 80%;
		margin-left: 20%;
	}
}
.helper {
	background: #22bafb;
	color: #fff;
	img {
		float: left;
		margin-right: 15px;
	}
	h4 {
		color: #fff;
		line-height: 100%;
		padding-top: 3px;
	}
}
.login {
	position: absolute;
	height: 100%;
	width: 100%;
	margin: 0;
	background: linear-gradient( #f6c12b 0%, #ee9935 100%);
	padding: 0;
	&:before {
		content: "";
		background: url("./assets/images/loading.png") no-repeat top center;
		width: 100%;
		height: 50%;
		bottom: 100px;
		position: absolute;
		background-size: contain;
		z-index: 2;
	}
	&:after {
		content: "";
		background: url("./assets/images/grid.png") repeat-x;
		width: 100%;
		height: 100%;
		bottom: 0;
		position: absolute;
	}
	div {
		margin: 120px auto 0;
		display: table;
		overflow: hidden;
		z-index: 3;
		position: relative;
	}
	label {
		float: left;
		margin: 30px 1px 0 0;
		width: 220px;
		color: #fff;
		background: none;
		border: none;
		padding: 0;
		input {
			margin: 5px 0 0 0;
			background: #f7f8fa;
			border: 1px solid #d5dde7;
			border-radius: 3px;
			padding: 10px;
		}
	}
	input.button {
		float: left;
		padding: 11px 22px;
		margin: 49px 0 0 1px;
	}
}
.message {
	padding: 30px;
	width: 100%;
	height: auto;
	background: rgba(0,0,0,0.5);
	color: #fff;
	text-align: center;
	font-weight: bold;
	position: absolute;
	z-index: 10;
}
.dashboard {
	article {
		p.welcome {
			padding: 100px;
			font-size: 18px;
			background: #eff0f5;
			border-radius: 3px;
			text-align: center;
		}
	}
}
.mycalendar {
	display: table;
	width: 48.5%;
	border: 1px solid #d5dde7;
	border-bottom: none;
	border-left: none;
	float: left;
	margin: 20px 20px 75px 0;
	+ {
		.mycalendar {
			margin-right: 0;
		}
	}
	h6 {
		background: #d5dde7;
		text-align: center;
		font-size: 15px;
		padding: 10px;
		margin: 0;
	}
	div {
		em {
			i {
				&:first-child {
					border-top: 1px solid #d5dde7;
				}
			}
			&:first-child {
				i {
					&:first-child {
						border-top: none;
						height: 50px;
						line-height: 50px;
						font-size: 12px;
						color: #4a5666;
					}
				}
			}
		}
	}
}
.mc-legend {
	float: left;
	width: 9%;
	font-size: 13px;
	em {
		i {
			font-weight: bold;
			font-style: normal;
			background: #fbfdff;
			color: #d5dde7;
			width: 100%;
			height: 50px;
			line-height: 50px;
			text-align: center;
			display: block;
			border: 1px solid #d5dde7;
			border-top: none;
			border-right: none;
		}
	}
}
.mc-day {
	em {
		i {
			font-weight: bold;
			font-style: normal;
			background: #fbfdff;
			color: #d5dde7;
			width: 100%;
			height: 50px;
			line-height: 50px;
			text-align: center;
			display: block;
			border: 1px solid #d5dde7;
			border-top: none;
			border-right: none;
			background: #eff0f5;
		}
		&:first-child {
			i {
				background: #fbfdff;
			}
		}
	}
	float: left;
	width: 13%;
	position: relative;
	border-bottom: 1px solid #fff;

	.slotholder {
		position: relative;
		width: 100%;
		top: 0;

		.firmholder, .teamholder {
			float: left;
		}

		span {
			cursor: pointer;
			max-height: 545px;

			&.placeholder {
				opacity: 0;
				height: 0;
				&:not([style]) {
					padding: 0;
					margin: 0;
				}
			}

			&.coverday {
				position: absolute;
				top: 545px;
				left: -30px;
				height: 75px;
				background: #fff;
				box-shadow: none;
				border-radius: 0;
				width: 1000px;
				opacity: 0.85;
				margin: 0;
				z-index: 99997;
			}
			&.coverweek {
				position: absolute;
				top: 550px;
				left: -5px;
				height: 60px;
				background: #fff;
				box-shadow: none;
				border-radius: 0;
				width: 105%;
				opacity: 0.85;
				margin: 0;
				z-index: 99997;
			}
		}
	}
	span {
		position: relative;
		// display: table;
		background: #aaa;
		color: #fff;
		font-size: 11px;
		margin: 5px 0 5px 5px;
		overflow: hidden;
		float: left;
		word-break: break-all;
		padding: 5px;
		height: 40px;
		width: 95%;
		top: 50px;
		border-radius: 5px;
		box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
		b {
			font-size: 12px;
			color: #fff;
		}
	}
	.calbase {
		position: absolute;
		width: 100%;
	}
}
.calendarhelper {
	margin: 30px 0 0;
	border-top: 1px solid #eff0f5;
	padding: 30px;
	display: table;
	width: 100%;
	span {
		float: left;
		padding: 0 30px 0 20px;
		background: transparent !important;

		&.e-kitchen, &.reclamation {
			margin: -1px 0 0 0;
			font-size: 12px;
			color: #fff;
			border-radius: 3px;
			font-weight: bold;
			margin: 15px 5px 0 0;
			padding: 3px 10px;
		}

		i {
			width: 14px;
			height: 14px;
			display: inline-block;
			border-radius: 7px;
			position: absolute;
			margin: 2px 0 0 -20px;
			background: #333;
		}
	}
}
span.e-momax {
	background: #72bf44;
	&:before {
		background: #72bf44;
	}
}
span.e-mobelix {
	background: #00b8f0;
	&:before {
		background: #00b8f0;
	}
}
span.e-kika {
	background: #ee4a2f;
	&:before {
		background: #ee4a2f;
	}
}
span.e-kitchen {
	background: #ef9c25 !important;
	&:before {
		background: #ef9c25;
	}
}
span.reclamation {
	background: #666 !important;
	&:before {
		background: #666;
	}
}
.calendar {
	.mycalendar {
		width: 100%;
		margin: 0 0 60px;
	}
}
.calendar2 {
	.mc-day {
		width: 91%;
		span {
			margin: 5px 0 5px 5px;
			width: 98%;
		}
	}
}
table {
	width: 100%;
}
thead {
	th {
		background: #22bafb;
		color: #fff;
		padding: 15px;
		text-align: left;
		-webkit-transition: all .5s;
		-moz-transition: all .5s;
		transition: all .5s;
	}
}
tbody {
	td {
		padding: 15px;
		text-align: left;
		-webkit-transition: all .5s;
		-moz-transition: all .5s;
		transition: all .5s;
		a {
			font-weight: bold;
			color: #111;
		}

		em {
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			line-height: 24px;
			text-align: center;
			color: #fff;
			background: #e54242;
			border-radius: 12px;
			margin: -3px 0 0 9px;
			height: 24px;
			width: 24px;
			display: inline-block;
			//position: absolute;
			&.green {
				background: #60a05d;
			}
			&.blue {
				background: #22bafb;
			}
			&.orange {
				background: #f29c17;
			}
		}
	}
	tr {
		border-bottom: 1px dotted #eff0f5;
		&:nth-child(2n+1) {
			td {
				background: #f7f8fa;
			}
		}
		&:hover {
			td {
				background: #eff0f5;
				color: #4a5666;
			}
		}
	}
}
.radio {
	input[type=radio] {
		&:checked {
			+ {
				label {
					color: #4a5666;
					color: #4a5666;
					&:before {
						border: 8px solid #f29c17;
					}
				}
			}
		}
		position: absolute;
		opacity: 0;
		width: 100%;
		height: 30px;
		z-index: 999;
		cursor: pointer;
		margin-left: -25px;
		+ {
			label {
				&:before {
					-webkit-transition: all .5s;
					-moz-transition: all .5s;
					transition: all .5s;
					content: '';
					position: absolute;
					width: 20px;
					height: 20px;
					border-radius: 20px;
					border: 3px solid #acbbd0;
					margin: -2px 0 0 -25px;
					box-sizing: border-box;
					pointer-events: none;
				}
			}
		}
	}
	strong {
		color: #acbbd0;
		background: #f7f8fa;
		border-radius: 3px;
		border: 1px solid #d5dde7;
		display: table;
		margin: 0 0 5px;
		padding: 10px;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: bold;
		width: 100%;
		background: transparent;
		border: none;
		padding-bottom: 0;
	}
	position: relative;
	display: table;
	width: 100%;
	margin: 10px 0 0;
	span {
		float: left;
		position: relative;
		display: inline-block;
		padding: 0 0 0 25px;
	}
	label {
		background: none;
		border: none;
		text-transform: none;
		font-size: 14px;
		width: auto;
		-webkit-transition: all .5s;
		-moz-transition: all .5s;
		transition: all .5s;
	}
}
i.delete {
	background: url("./assets/images/icon_delete.png");
	display: inline-block;
	width: 30px;
	height: 30px;
	float: left;
	cursor: pointer;

	&.furniture {
		background-color: #fff;
		border-radius: 15px;
		right: 50px;
		margin-top: -15px;
		position: absolute;
		z-index: 99;
	}
}
i.print {
	background: url("./assets/images/icon_delete.png");
	display: inline-block;
	width: 30px;
	height: 30px;
	float: left;
	cursor: pointer;
	background: url("./assets/images/icon_print.png");
}
form {
	padding: 15px 0 45px;
	display: table;
	width: 100%;
}
label {
	color: #acbbd0;
	background: #f7f8fa;
	border-radius: 3px;
	border: 1px solid #d5dde7;
	display: table;
	margin: 0 0 5px;
	padding: 10px;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	width: 100%;
}
input[type=text] {
	width: 100%;
	cursor: text;
	resize: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	outline: none;
	border: none;
	padding: 10px 0 5px;
	font-size: 14px;
	color: #4a5666;
	background: transparent;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
}
input[type=email] {
	width: 100%;
	cursor: text;
	resize: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	outline: none;
	border: none;
	padding: 10px 0 5px;
	font-size: 14px;
	color: #4a5666;
	background: transparent;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
}
input[type=submit] {
	width: 100%;
	cursor: text;
	resize: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	outline: none;
	border: none;
	padding: 10px 0 5px;
	font-size: 14px;
	color: #4a5666;
	background: transparent;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
}
input[type=password] {
	width: 100%;
	cursor: text;
	resize: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	outline: none;
	border: none;
	padding: 10px 0 5px;
	font-size: 14px;
	color: #4a5666;
	background: transparent;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
}
input[type=number] {
	width: 100%;
	cursor: text;
	resize: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	outline: none;
	border: none;
	padding: 10px 0 5px;
	font-size: 14px;
	color: #4a5666;
	background: transparent;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
}
select {
	width: 100%;
	cursor: text;
	resize: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	outline: none;
	border: none;
	padding: 10px 0 5px;
	font-size: 14px;
	color: #4a5666;
	background: transparent;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
	cursor: pointer;
	background: transparent !important;
}
textarea {
	width: 100%;
	cursor: text;
	resize: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	outline: none;
	box-shadow: none;
	border: none;
	padding: 15px;
	font-size: 13px;
	color: #4a5666;
	background: transparent;
	min-height: 50px;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
}
input[type=date] {
	text-transform: uppercase;
}
input {
	&::-webkit-calendar-picker-indicator {
		display: none;
	}
}
input[type="date"] {
	&::-webkit-input-placeholder {
		visibility: hidden !important;
	}
}
input[type="submit"], a.button {
	background: #f29c17;
	font-weight: bold;
	color: #fff;
	border: none;
	display: table;
	padding: 15px 25px 15px;
	width: auto !important;
	cursor: pointer;
	float: left;
	margin: 15px 5px 0 0px;
	font-size: 14px;
	&:hover {
		background: #4a5666;
		color: #fff;
	}
}
.imageselector {
	input[type=radio] {
		height: 100px;
		+ {
			label {
				&:before {
					width: 80px;
					height: 80px;
					top: 0;
					left: 25px;
					z-index: 1;
					border-style: solid;
					border-width: 3px !important;
					border-radius: 40px;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 40px 40px;
				}
				&:after {
					content: '';
					display: block;
					position: absolute;
					background: #f7f8fa;
					width: 80px;
					height: 80px;
					border-radius: 40px;
					top: 0;
					left: 0;
				}
			}
		}
		&:checked {
			+ {
				label {
					color: #4a5666;
				}
			}
		}
	}
	span {
		padding: 0 20px 0 0;
		margin: 10px 0 0;
	}
	label {
		text-align: center;
		padding: 90px 0 0;
		width: 80px;
		small {
			font-size: 12px;
			display: block;
			font-weight: normal;
			padding: 10px;
		}
	}
	label.f-delivery {
		&:before {
			background-image: url("./assets/images/f_delivery.png");
		}
	}
	label.f-couch {
		&:before {
			background-image: url("./assets/images/f_sofa.png");
		}
	}
	label.f-bed, label.f-bedCouch {
		&:before {
			background-image: url("./assets/images/f_bed.png");
		}
	}
	label.f-furniture {
		&:before {
			background-image: url("./assets/images/f_boxed.png");
		}
	}
	label.f-kitchenSurvey {
		&:before {
			background-image: url("./assets/images/f_kitchenSurvey.png");
		}
	}
	label.f-kitchen {
		&:before {
			background-image: url("./assets/images/f_kitchen.png");
		}
	}
	label.f-plannedKitchen {
		&:before {
			background-image: url("./assets/images/f_kitchen.png");
		}
	}
}
.colorpick {
	input[type=radio] {
		+ {
			label {
				&:before {
					background: currentColor;
					opacity: 0.3;
					border: none;
					border-radius: 3px;
				}
				span {
					padding: 0;
					color: #acbbd0;
				}
			}
		}
		&:checked {
			+ {
				label {
					&:before {
						opacity: 1;
						border: none;
					}
					span {
						color: #4a5666;
					}
				}
			}
		}
	}
}

.selectbox {
	background: #f7f8fa url("./assets/images/arrows.png") no-repeat right center !important;
}
.selectbox.multiselect {
	background: #f7f8fa !important;
	span {
		display: table;
		width: 100%;
		padding: 10px;
	}
}
select[multiple] {
	height: 130px;
	background: #fff !important;
	border: 1px solid #d5dde7;
	border-radius: 3px;
	option {
		padding: 10px;
		border-bottom: 1px dashed #d5dde7;
		&:checked {
			background: linear-gradient(#22bafb, #22bafb);
			font-weight: bold;
		}
	}
}
label.inputtype2 {
	padding: 0;
	overflow: hidden;
	display: flex;
	position: relative;
	span {
		float: left;
		width: auto;
		background: #eff0f5;
		border-right: 1px solid #d5dde7;
		padding: 15px 10px;
	}
	input, select {
		padding: 14px;
		width: 100%;
		border: none;
		background: transparent;
		display: inline-flex;
	}
}
label.inputtype2.lightblue {
	span {
		background: #22bafb;
		border-right: none;
		color: #fff;
	}
}
label.inputtype2.orange {
	span {
		background: #f29c17;
		border-right: none;
		color: #fff;
	}
}
.checkbox {
	input[type=checkbox] {
		margin-right: 5px;
	}
	label {
		display: table;
		text-transform: none;
		font-size: 14px;
		width: auto;
		float: left;
		margin: 0 3px 3px 0;
	}
}
.switch {
	border: none;
	background: transparent;
	span {
		float: left;
		line-height: 30px;
		margin: 0 5px 0 0;
	}
	div {
		position: relative;
		float: left;
		input[type=checkbox] {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 60px;
			height: 100%;
			cursor: pointer;
			z-index: 999;
		}
		input {
			+ {
				span {
					background: #4a5666;
					width: 100%;
					height: 100%;
					width: 60px;
					height: 30px;
					border-radius: 15px;
					-webkit-transition: all .5s;
					-moz-transition: all .5s;
					transition: all .5s;
					z-index: 997;
					+ {
						span {
							content: '';
							width: 24px;
							height: 24px;
							background: #fff;
							border-radius: 12px;
							position: absolute;
							left: 3px;
							top: 3px;
							display: inline-block;
							-webkit-transition: all .5s;
							-moz-transition: all .5s;
							transition: all .5s;
							z-index: 998;
						}
					}
				}
			}
			&:checked {
				+ {
					span {
						background: #f29c17;
						+ {
							span {
								left: 32px;
							}
						}
					}
				}
			}
		}
	}
}
#szallitmany {
	.switch {
		display: inline-block;
		width: auto;
	}
}
.topbuttons {
	display: table;
	position: relative;
	width: 100%;
	.spinner {
		width: 32px;
  	right: 0;
	}
	input {
		+ {
			small {
				float: left;
				padding: 30px 20px 0;
			}
		}
	}
	input[type="file"] {
		display: none;
	}
	.filecontainer {
		background: none;
		border: none;
		margin: 0;
		padding: 0;
		float: left;
		text-transform: none;

		.button {
			background: #4a5666;
		}
	}
}
.tabhead {
	display: table;
	clear: both;
	margin: 30px 0 0;
	li {
		float: left;
		a {
			background: #4a5666;
			color: #fff;
			padding: 15px 20px;
			margin: 0 1px 0 0;
			font-weight: bold;
			font-size: 14px;
			&:hover {
				background: #22bafb;
			}
		}
		a.active {
			background: #f29c17;
		}
	}
}
.tabcontent {
	border: 1px solid #d5dde7;
	padding: 30px;
	margin: -1px 0 0 0;
	border-radius: 0 5px 5px 5px;

	h2 {
		padding: 100px 50px 70px;
		text-align: center;
		font-size: 18px;
		color: #aaa;
		background: none;
	}
}
.innerbox {
	border-radius: 3px;
	background: #f7f8fa;
	padding: 15px 20px;
	.switch {
		width: auto;
		display: inline-block;
		text-transform: none;
		font-size: 13px;
		padding: 5px;
	}
	.selectbox {
		display: flex;
	}
}
.imgcont {
	clear: both;
	display: table;
	margin: 30px 0 30px;
	a {
		display: initial;
	}
	img {
		border-radius: 10px;
		padding: 5px;
		width: 20%;
	}
}
.imgcont2 {
	clear: both;
	display: table;
	margin: 30px 0 30px;
	padding: 30px;
	div {
		width: 33.33%;
		cursor: pointer;
		display: inline-block;
		position: relative;
		height: 100px;
		overflow: hidden;
		z-index: 99999;

		.image {
			width: 100%;
		}

		.actionIcon {
			display: none;
			position: absolute;
			top: 50%;
    	left: 50%;
			transform: translate(-200%,-50%);
			background: #fff;
			border-radius: 5px;
			height: 30px;
			width: 30px;
			&.fs {
				transform: translate(-40%,-50%);
			}
			&.del {
				transform: translate(120%,-50%);
			}
		}

		&:hover {
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(34, 186, 251, 0.8);
			}
			.actionIcon {
				display: block;
			}
		}

		&.grow {
			z-index: 999999;

			&:before {
				content: '';
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 9;
				background: rgba(0,0,0,0.6);
			}
			.image {
				position: fixed;
				top: 0;
				height: 100%;
				max-width: 100%;
				width: auto;
				z-index: 1000;
				left: 50%;
				transform: translate(-50%,0);
			}
		}
	}
}
.imgcont3 {
	clear: both;
	display: table;
	margin: 70px 0 0;
	a {
		display: initial;
	}
	img {
		border-radius: 10px;
		margin: 0 0 15px;
		padding: 5px;
		width: 20%;
	}
	img+span {
		font-size: 12px;
		height: 15px;
		margin: 95px 0 0 -120px;
		overflow: hidden;
		position: absolute;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 12%;
	}
}
#csakkonyha {
	margin-bottom: 30px;
}
.quicknumbers {
	background: #f29c17;
	border-radius: 3px 3px 0 0;
	padding: 30px;
	margin: -30px -30px 30px;
	border-bottom: 5px solid #fff;
	h1 {
		margin: 0;
		span {
			display: block;
			text-transform: uppercase;
			font-size: 12px;
			opacity: 0.5;
			font-weight: bold;
		}
		strong {
			color: #fff;
		}
	}
	h3 {
		margin: 0;
		span {
			display: block;
			text-transform: uppercase;
			font-size: 12px;
			opacity: 0.5;
		}
		strong {
			color: #fff;
		}
	}
}
.dayview {
	position: relative;
	h4 {
		b {
			font-size: 14px;
			display: block;
		}
	}
	em {
		i {
			font-style: normal;
			background: #fbfdff;
			color: #fff;
			width: 10%;
			height: 60px;
			line-height: 40px;
			text-align: center;
			display: inline-block;
			border: 1px solid #d5dde7;
			font-size: 13px;
			border-width: 0 0 0 1px;

			&:first-of-type {
				border: none;
			}
		}
	}
	.slotHolder {
		position: relative;
		height: 100px;
	}
	.bookslots {
		position: absolute;
		width: 100%;
		top: 0;

		& > div {
			font-size: 10px;
			color: #ccc;
			position: absolute;
			top: -20px;
			right: 0;
			text-transform: uppercase;
		}
	}
	.bookslots.bsdu {
		left: 50%;
		//top: 220px;
	}
	span {
		position: relative;
		float: left;
		margin: 15px .5% 5px;
		padding: 5px;
		font-size: 10px;
		width: 9%;
		overflow: hidden;
		max-width: 109%;
		border-radius: 5px;
		background: #4a5666;
		color: #fff;
		box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
		b {
			font-size: 12px;
		}
		&.cover {
			position: absolute;
			left: 100%;
			height: 75px;
			background: #fff !important;
			box-shadow: none;
			border-radius: 0;
			width: 25%;
			opacity: 0.85;
			margin: 0;
			z-index: 99999;
		}
	}
	span.orderslot {
		background: #22bafb;
		&:before {
			background: #4a5666;
		}
		u {
			text-decoration: none;
		}
	}
	span.nemjo {
		background: red;
	}
	span.placeholder {
		background: #4a5666;
    color: #4a5666;
    box-shadow: none;
    opacity: 0.15;
	}
}
.nextdays {
	position: absolute;
	right: 0;
	top: 0;
	display: table;
	display: none;
	span {
		width: 50px;
		font-size: 10px;
		background: none;
		box-shadow: none;
		color: #4a5666;
		text-align: center;
		cursor: pointer;
		margin: 0 5px 0 0;
		border-left: 1px dotted #ccc;
		padding: 0 15px;
		border-radius: 0;
		&:before {
			content: '';
			display: inline-block;
			background: #22bafb;
			width: 6px;
			height: 6px;
			border-radius: 3px;
			margin: 0 0 5px;
		}
	}
	span.tiltott {
		&:before {
			background: #eee;
		}
	}
	span.tele {
		&:before {
			background: #e82d4a;
		}
	}
	span.nemfer {
		&:before {
			background: #c65466;
		}
	}
	span.ures {
		&:before {
			background: #8dc182;
		}
	}
}
.mmatrix {
	display: table;
	width: 100%;
	border: 1px solid #d5dde7;
	border-radius: 3px;
	padding: 30px;
	margin: 0 0 5px;
	.selectbox {
		float: left;
		width: 32%;
		margin-right: 5px;
		select[multiple] {
			height: 500px;
		}
	}
}
.navbuttons {
	display: table;
	margin: -80px 0 30px;
	input {
		+ {
			.radio {
				width: auto;
				padding: 8px 12px;
			}
		}
	}
	+ {
		h2 {
			margin: 0;
		}
	}

	.filters {
		float: left;
		padding: 15px 0 0 0;

		.companyChanger {
			display: none;
		}

		label {
			min-width: 250px;
			max-width: 50%;
			float: left;
		}
	}
}
.filterandexport {
	display: table;
	background: #fafafa;
	width: 100%;
	margin: 0 0 30px;
	padding: 30px;
	border-radius: 3px;
	border: 1px solid #d5dde7;
	label {
		float: left;
		width: 24%;
		margin-right: 5px;
	}
	div {
		input[type=submit] {
			margin: 0 5px 0 0;
		}
	}
	i.mf {
		background: #eff0f5 url("./assets/images/icon_print.png");
		padding: 22px;
		background-position: center center;
		background-repeat: no-repeat;
		display: inline-block;
		border-radius: 3px;
		border: 1px solid #d5dde7;
		cursor: pointer;
	}
	.morefilter {
		clear: both;
		padding: 50px 0 0;

		div label {
			width: 48.5%;
			span {
				white-space: nowrap;
			}
		}
		label.switch {
			width: auto;
		}
	}
}
#printcontent {
	position: fixed;
	top: 0;
	left: 3000px;
	width: 2480px;
	height: 3508px;
	padding: 30px;
}

.readonly {
	pointer-events: none;
	span {
		opacity: 0.75;
	}
	input {
		color: #acbbd0;
	}
}

.naDates {
	background-color: red !important;
}

.visibleKitchens {
	border-right: 2px solid red !important;
}

.notsaved {
  background: red;
  color: #fff;
  font-size: 14px;
  padding: 20px 30px;
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
	z-index: 9999;

	a {
		font-weight: bold;
		cursor: pointer;
	}
}

.mobilemenu {
	padding: 10px;
	cursor: pointer;
	left: 0;
	top: 0;
	position: fixed;
	z-index: 9999;
}

.actionspanel:before {
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.7);
	z-index: 99998;
	position: fixed;
}

.apinside {
  background: #fff;
  border-radius: 5px;
  padding: 30px;
  z-index: 99999;
  position: fixed;
  width: 600px;
  left: 50%;
  margin-left: -300px;
  top: 20%;

  .b2 {
    background: #22bafb;
	}

	.b3 {
		background: #e54242;
		//display: none;
	}

  h4 {
    margin: 0 0 15px;
  }

  h5 {
    margin: 0 0 5px;
    font-weight: bold;
  }
}

.choices {
	position: absolute;
	width: 90%;
	margin: -10px 0 0;
	background: #fff;
	border-radius: 0 5px 5px 5px;
	z-index: 999;
	padding: 15px;
	border: 1px solid #d5dde7;
	list-style: none;
	box-shadow: 20px 20px 20px rgba(0,0,0,0.2);

  li {
    width: 33%;
    float: left;
    font-size: 16px;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      color: #000;
      font-weight: bold;
      background: #fafafa;
      border-radius: 3px;
    }

    br {
      display: none;
    }
  }
}

.kivonat {
	background: #f7f8fa;
	border-radius: 3px;
	border: 1px solid #d5dde7;
	margin: 0 0 15px;
	padding: 15px;
	position: relative;
	small {
		color: #acbbd0;
		font-size: 12px;
		font-weight: bold;
		text-transform: uppercase;
	}
	p {
		color: #4a5666;
	}
	.rdatum {
		background: #4a5666;
		border-radius: 3px;
		color: #ffffff;
		font-size: 13px;
		font-weight: bold;
		padding: 10px;
		position: absolute;
		right: 15px;
		text-transform: uppercase;
		top: 15px;
	}
	span {
		background: #eff0f5;
		border-radius: 3px;
		color: #4a5666;
		display: inline-block;
		font-size: 13px;
		font-weight: bold;
		margin: 0 1px 5px 1px;
		padding: 10px;
		b {
			color: #acbbd0;
			font-weight: normal;
		}
	}
	.rcim {
		margin: -10px 0 30px;
	}
}

.calset {
	position: relative;
	.navbuttons {
		float: right;
		margin: -60px 0 0;
	}
	.csm {
		display: table;
		width: 100%;
		border: 1px solid #d5dde7;
		border-bottom: 0;
		border-right: 0;
		margin: 30px 0 0;
	}
	em {
		i {
			font-size: 13px;
			font-weight: bold;
			font-style: normal;
			text-align: center;
			background: #fbfdff;
			color: #75848d;
			height: 40px;
			line-height: 40px;
			width: 14.285%;
			float: left;
			display: block;
			cursor: pointer;
			border: 1px solid #d5dde7;
			border-top: none;
			border-left: none;
		}
		i.halfday {
			background: #fff2df;
			color: #ffaf30;
		}
		i.h8 {
			background: #eff0f5;
			color: #acbbd0;
		}
		i.noday {
			background: #ffe5e5;
			color: #ff9a9a;
		}
		i.marked {
			background: #000;
			color: #fff;
		}
	}
	em.csmh {
		i {
			background: #22bafb;
			color: #fff;
		}
	}
}
.adcal {
	position: absolute;
	right: 230px;
	top: 15px;
	display: table;
	font-size: 13px;
	border: 1px solid #eee;
	padding: 5px 15px 5px 0;
	border-radius: 30px;
	cursor: pointer;
	span {
		&:before {
			content: '';
			display: inline-block;
			background: #aaa;
			width: 10px;
			height: 10px;
			border-radius: 99px;
			margin: 0px 3px 1px 15px;
			transform: translateY(2px);
			border: 1px solid;
		}
		&.h10::before {
			background: #fff;
			border: 1px solid;
		}
	}
	span.halfday {
		&:before {
			background: #f0b85e;
		}
	}
	span.h8 {
		&:before {
			background: #acbbd0;
		}
	}
	span.noday {
		&:before {
			background: #ff9a9a;
		}
	}
}
.recommend {
  background: #f29c17;
  color: #fff;
  font-size: 14px;
  padding: 15px;
  border-radius: 3px;
  text-align: center;
}
.dpContainer {
	position: relative;
  min-height: 30px;
  display: block;
}
#szekrenytipus {
	label.inputtype2 {
		float: left;
		width: 32.5%;
		margin-right: 0.5%;

		span {
			width: 500%;
			padding: 10px;
			max-width: 110px;
			text-transform: none;
		}

		&:last-of-type {
			float: none;
			width: 99%;

			span {
				max-width: 1000px;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.mobilemenu {
		display: block !important;
		cursor: pointer;
	}
	.login {
		div {
			margin: 50px;
		}
		&:before {
			background-position: bottom center;
			background-size: 80%;
		}
	}
	header {
		width: auto;
		z-index: 99999;
		position: relative;
		float: left;
		.avatar {
			float: left;
			padding: 5px;
			figcaption {
				display: none;
			}
		}
		.avacont {
			display: none;
		}
	}
	.navigation {
		width: 300px;
		margin-left: -600px;
		z-index: 99998;
		box-shadow: 0 0 200px rgba(0,0,0,0.5);
	}
	section {
		width: 100%;
		margin-left: 0;
		padding: 20px;
		min-height: 100vh;
		a.button {
			float: right;
		}
	}
	article {
		float: none;
		width: 100%;
		padding: 0;
		h2 {
			clear: both;
			margin: 0 0 20px;
			+ {
				i.delete {
					right: 30px;
					top: 50px;
				}
			}
		}
		+ {
			article {
				float: none;
				width: 100%;
				padding: 20px;
				clear: both;
			}
		}
	}
	.quicknumbers {
		margin: -20px -20px 20px;
	}
	.tabhead {
		li {
			a {
				padding: 12px;
				font-size: 12px;
				text-transform: none;
			}
		}
	}
	.imageselector {
		span {
			padding: 0 5px;
			font-size: 21px;
			font-weight: bold;
		}
		label {
			width: 60px;
			font-size: 12px;
			word-break: break-word;
		}
		input[type=radio] {
			+ {
				label {
					&:before {
						width: 60px;
						height: 60px;
						background-size: 30px 30px;
					}
					&:after {
						width: 60px;
						height: 60px;
					}
				}
			}
		}
	}
	label {
		text-transform: none;
	}
	.radio {
		strong {
			text-transform: none;
		}
	}
	input[type="submit"] {
		margin: 5px 5px 0 0px;
	}
	a.button {
		margin: 5px 5px 0 0px;
	}
	.addnew {
		margin: 0px 0 40px !important;
		z-index: 9999;
	}
	.filterandexport {
		label {
			float: none;
			width: 100%;
		}
	}
	thead {
		th {
			padding: 5px 10px;
			font-size: 13px;
		}
	}
	tbody {
		td {
			padding: 5px 10px;
			font-size: 13px;
			a {
				width: 100%;
    		margin: 5px 0;
			}
			em {
				margin: 0 5px 5px 0;
			}
		}
	}
	tbody td, thead th {
		min-width: 90px;
	}
	.mycalendar {
		display: table;
		width: 95%;
		div {
			em {
				&:first-child {
					i {
						&:first-child {
							text-overflow: ellipsis;
							padding: 0px 15px;
							overflow: hidden;
						}
					}
				}
			}
		}
	}
	.calendar2 {
		.mc-day {
			span {
				margin: 5px 0 0 3px;
			}
		}
	}
	.mc-day span {
		padding: 3px;
    letter-spacing: -1px;
	}
	.mmatrix {
		padding: 20px;
		.selectbox {
			width: 95px !important;
			padding: 0px;
		}
	}
	.apinside { width: 300px; margin-left: -150px; }
	.navbuttons { margin: 50px 0 30px; }
}
