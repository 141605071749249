.spinner {
  position: absolute;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    width: 32px;
    height: 32px;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
  }
}
