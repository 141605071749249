/*** GridCMS Framework CSS 1.1 */
/*** TOC ***/
/********************

	1. Reset (line 15)
	2. Base (line 50)
	3. Grid (line 65)
	4. Responsive ()
	5. Extras ()

********************/
/*** RESET ***/
/*** BASE ***/
/* Reset Box-Sizing */
/*** GRID ***/
html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
body {
	margin: 0;
}
h1 {
	margin: 0;
	padding: 0;
	font-size: inherit;
}
h2 {
	margin: 0;
	padding: 0;
	font-size: inherit;
}
h3 {
	margin: 0;
	padding: 0;
	font-size: inherit;
}
h4 {
	margin: 0;
	padding: 0;
	font-size: inherit;
}
h5 {
	margin: 0;
	padding: 0;
	font-size: inherit;
}
h6 {
	margin: 0;
	padding: 0;
	font-size: inherit;
}
p {
	margin: 0;
	padding: 0;
	margin-bottom: 15px;
	&:last-child {
		margin-bottom: 0;
	}
}
blockquote {
	margin: 0;
	padding: 0;
}
figure {
	margin: 0;
	padding: 0;
}
ol {
	margin: 0;
	padding: 0;
}
ul {
	margin: 0;
	padding: 0;
}
li {
	display: block;
	position: relative;
}
a {
	text-decoration: none;
	color: inherit;
	position: relative;
	display: inline-block;
	-webkit-transition: .3s;
	-moz-transition: .3s;
	transition: .3s;
	&:active {
		outline: 0;
	}
	&:hover {
		outline: 0;
	}
}
b {
	font-weight: bold;
}
strong {
	font-weight: bold;
}
small {
	font-size: 80%;
}
sub {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
	bottom: -0.25em;
}
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
	top: -0.5em;
}
img {
	max-width: 100%;
	height: auto;
	border: 0;
}
hr {
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
}
button {
	color: inherit;
	font: inherit;
	margin: 0;
	outline: none;
	text-transform: none;
}
input {
	color: inherit;
	font: inherit;
	margin: 0;
	outline: none;
}
optgroup {
	color: inherit;
	font: inherit;
	margin: 0;
	outline: none;
}
select {
	color: inherit;
	font: inherit;
	margin: 0;
	outline: none;
	text-transform: none;
}
textarea {
	color: inherit;
	font: inherit;
	margin: 0;
	outline: none;
	overflow: auto;
}
input[type="search"] {
	-webkit-appearance: textfield;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	&::-webkit-search-cancel-button {
		-webkit-appearance: none;
	}
	&::-webkit-search-decoration {
		-webkit-appearance: none;
	}
}
fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
td {
	padding: 0;
}
th {
	padding: 0;
}
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
}
.container {
	margin: 0 auto;
	max-width: 100%;
	width: 960px;
}
.row {
	margin-left: auto;
	margin-right: auto;
	&:after {
		clear: both;
		content: "";
		display: block;
		height: 0;
		line-height: 0;
		visibility: hidden;
	}
	[class*="col"] {
		float: left;
	}
	.boxed {
		padding: 20px 0;
		>* {
			padding-left: 15px;
			padding-right: 20px;
		}
	}
	.boxed30 {
		padding: 30px 0;
		>* {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	.boxed50 {
		padding: 50px 0;
		>* {
			padding-left: 50px;
			padding-right: 50px;
		}
	}
}
.hide {
	display: none;
}
.hiderow {
	.row {
		display: none;
	}
}
.gap36 {
	.row {
		margin-left: -1.875%;
		margin-right: -1.875%;
		[class*="col"] {
			margin-left: 1.875%;
			margin-right: 1.875%;
		}
		.col-1p1 {
			width: 96.25%;
		}
		.col-1p2 {
			width: 46.25%;
		}
		.col-1p3 {
			width: 29.583333%;
		}
		.col-2p3 {
			width: 62.916667%;
		}
		.col-1p4 {
			width: 21.25%;
		}
		.col-3p4 {
			width: 71.25%;
		}
		.col-1p5 {
			width: 16.25%;
		}
		.col-2p5 {
			width: 36.25%;
		}
		.col-3p5 {
			width: 56.25%;
		}
		.col-4p5 {
			width: 76.25%;
		}
		.col-1p6 {
			width: 12.916667%;
		}
		.col-5p6 {
			width: 79.583333%;
		}
	}
}
.gap24 {
	.row {
		margin-left: -1.25%;
		margin-right: -1.25%;
		[class*="col"] {
			margin-left: 1.25%;
			margin-right: 1.25%;
		}
		.col-1p1 {
			width: 97.5%;
		}
		.col-1p2 {
			width: 47.5%;
		}
		.col-1p3 {
			width: 30.833333%;
		}
		.col-2p3 {
			width: 64.166667%;
		}
		.col-1p4 {
			width: 22.5%;
		}
		.col-3p4 {
			width: 72.5%;
		}
		.col-1p5 {
			width: 17.5%;
		}
		.col-2p5 {
			width: 37.5%;
		}
		.col-3p5 {
			width: 57.5%;
		}
		.col-4p5 {
			width: 77.5%;
		}
		.col-1p6 {
			width: 14.166667%;
		}
		.col-5p6 {
			width: 80.833333%;
		}
	}
}
.gap12 {
	.row {
		margin-left: -0.625%;
		margin-right: -0.625%;
		[class*="col"] {
			margin-left: 0.625%;
			margin-right: 0.625%;
		}
		.col-1p1 {
			width: 98.75%;
		}
		.col-1p2 {
			width: 48.75%;
		}
		.col-1p3 {
			width: 32.083333%;
		}
		.col-2p3 {
			width: 65.416667%;
		}
		.col-1p4 {
			width: 23.75%;
		}
		.col-3p4 {
			width: 73.75%;
		}
		.col-1p5 {
			width: 18.75%;
		}
		.col-2p5 {
			width: 38.75%;
		}
		.col-3p5 {
			width: 58.75%;
		}
		.col-4p5 {
			width: 78.75%;
		}
		.col-1p6 {
			width: 15.416667%;
		}
		.col-5p6 {
			width: 82.083333%;
		}
	}
}
.gap0 {
	.row {
		margin-left: 0%;
		margin-right: 0%;
		[class*="col"] {
			margin-left: 0%;
			margin-right: 0%;
		}
		.col-1p1 {
			width: 100%;
		}
		.col-1p2 {
			width: 50%;
		}
		.col-1p3 {
			width: 33.333333%;
		}
		.col-2p3 {
			width: 66.666667%;
		}
		.col-1p4 {
			width: 25%;
		}
		.col-3p4 {
			width: 75%;
		}
		.col-1p5 {
			width: 20%;
		}
		.col-2p5 {
			width: 40%;
		}
		.col-3p5 {
			width: 60%;
		}
		.col-4p5 {
			width: 80%;
		}
		.col-1p6 {
			width: 16.666667%;
		}
		.col-5p6 {
			width: 83.333333%;
		}
	}
}
